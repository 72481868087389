import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CreateAccountComponent } from '../register/create-account/create-account.component';
import intlTelInput from 'intl-tel-input';
import { AuthService } from '../auth.service';
import { ToastService } from '../../core/services/toast.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent {
  @ViewChild('phoneNumber', { static: true }) phoneNumberInput!: ElementRef;
  forgotPasswordForm!: FormGroup;
  private iti: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    const input = this.phoneNumberInput.nativeElement;
    this.iti = intlTelInput(input, {
      initialCountry: 'id',
      separateDialCode: true,
      formatAsYouType: true,
      showFlags: true
    });

    input.addEventListener('paste', (event: ClipboardEvent) => {
      event.preventDefault();

      const pastedText = event.clipboardData?.getData('text') || '';
      if (pastedText) {
        let formattedNumber = pastedText.replace(/\s+/g, '').replace(/-/g, '');

        if (!formattedNumber.startsWith('+') && !formattedNumber.startsWith('0')) {
          formattedNumber = '+' + formattedNumber;
        }

        formattedNumber = formattedNumber.replace(/^0+/, '');

        this.iti.setNumber(formattedNumber);
      }
    });

    this.forgotPasswordForm = this.fb.group({
      phoneNumber: ['', Validators.required],
    });
  }

  onSubmit(event: Event) {
    event.preventDefault();
    const countryCode = this.iti.getSelectedCountryData().dialCode;
    const phoneNumber = this.forgotPasswordForm.get('phoneNumber')?.value.replace(/[^0-9]/g, '').replace(/^0+/, '');
    
    if (this.forgotPasswordForm.valid) {
      const fullPhoneNumber = countryCode + phoneNumber;
      
      this.auth.sendResetPasswordOtp(fullPhoneNumber).subscribe({
        next: (response) => {
          this.toast.show({textOrHtml: 'We have sent you an OTP. Please check your phone.', classname: 'bg-success text-white', delay: 1500});
          this.router.navigate(['/forgot-password/verify-otp'], {
            state: { ...this.forgotPasswordForm.value, phoneNumber: fullPhoneNumber },
          });
        },
        error: (error) => {
          this.toast.show({textOrHtml: error.error.message, classname: 'bg-danger text-white', delay: 1500});
        },
      });
    }
  }

  goBack() {
    this.router.navigate(['/login']);
  }
}
