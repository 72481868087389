import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../core/services/toast.service';
import { AuthService } from '../../auth.service';
import intlTelInput from 'intl-tel-input';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent {
  @ViewChild('phoneNumber', { static: true }) phoneNumberInput!: ElementRef;
  phoneNumberInstance: any;
  accountForm!: FormGroup;
  activeRole: string = '';
  private iti: any;
  isProcessing: boolean = false;
  showPassword: boolean = false;
  referrer!: string | null;
  qrRef: boolean = false;
  icons = { faEyeSlash, faEye }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthService,
    private toast: ToastService,
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.activeRole = navigation.extras.state['activeRole'];
      this.referrer = navigation.extras.state['referrer'];
      if (this.referrer) {
        this.qrRef = true;
      }
    } else {
      console.log('No navigation state available');
      this.router.navigate(['/register']);
    }
  }

  ngOnInit() {
    const input = this.phoneNumberInput.nativeElement;
    this.iti = intlTelInput(input, {
      initialCountry: 'id',
      separateDialCode: true,
      formatAsYouType: true,
      showFlags: true
    });

    input.addEventListener('paste', (event: ClipboardEvent) => {
      event.preventDefault();

      const pastedText = event.clipboardData?.getData('text') || '';
      if (pastedText) {
        let formattedNumber = pastedText.replace(/\s+/g, '').replace(/-/g, '');

        if (!formattedNumber.startsWith('+') && !formattedNumber.startsWith('0')) {
          formattedNumber = '+' + formattedNumber;
        }

        formattedNumber = formattedNumber.replace(/^0+/, '');

        this.iti.setNumber(formattedNumber);
      }
    });

    if (this.activeRole === 'Franchisor') {
      this.accountForm = this.fb.group({
        activeRole: [this.activeRole, [Validators.required]],
        name: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        isFranchisor: [true, [Validators.required]],
        referrer: [this.referrer],
        // Franchisor fields
        entity: ['', [Validators.required]],
        brandName: ['', [Validators.required]],
        founded: ['', [Validators.required]],
      });
    }
    if (this.activeRole === 'Franchisee') {
      this.accountForm = this.fb.group({
        activeRole: [this.activeRole, Validators.required],
        name: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        isFranchisee: [true, [Validators.required]],
        referrer: [this.referrer],
      });
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  isControlInvalid(controlName: string, errorType?: string): boolean | undefined {
    const control = this.accountForm.get(controlName);

    if (!control) {
      return undefined;
    }

    if (errorType) {
      return control.hasError(errorType) && (control.touched || control.dirty);
    } else {
      return control.invalid && (control.touched || control.dirty);
    }
  }

  onSubmit(event: Event) {
    event.preventDefault();
    const countryCode = this.iti.getSelectedCountryData().dialCode;
    const phoneNumber = this.accountForm.get('phoneNumber')?.value.replace(/[^0-9]/g, '').replace(/^0+/, '');
    if (this.accountForm.invalid) {
      this.accountForm.markAllAsTouched();
      return;
    } else {
      this.isProcessing = true;
      const accountDetail = {
        ...this.accountForm.value,
        phoneNumber: countryCode + phoneNumber
      };

      this.auth.register(accountDetail).subscribe({
        next: (response: Response) => {
          this.isProcessing = false;
          this.toast.show({textOrHtml: 'Successfully registered!', classname: 'bg-success text-white', delay: 1500});
          this.router.navigate(['/register/account-verification'], {
            state: { ...accountDetail },
          });
        },
        error: (error) => {
          this.isProcessing = false;
          if (error.error.message === 'You are already registered, please proceed to login') {
            this.toast.show({textOrHtml: `You are already registered, please proceed to <a href="/login">login</a>`, classname: 'bg-danger text-white', delay: 1500});
          } else {
            this.toast.show({textOrHtml: error.error.message, classname: 'bg-danger text-white', delay: 1500});
          }
        }
      });
    }
  }

  getInvalidControls(): string[] {
    const invalidControls: string[] = [];
    Object.keys(this.accountForm.controls).forEach(controlName => {
      const control = this.accountForm.get(controlName);
      if (control && control.invalid) {
        invalidControls.push(controlName);
      }
    });
    return invalidControls;
  }

}
