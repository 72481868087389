import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../core/services/api.service';
import { ToastService } from '../../../core/services/toast.service';

@Component({
  selector: 'app-basic-information',
  templateUrl: './basic-information.component.html',
  styleUrls: ['./basic-information.component.scss'],
})
export class BasicInformationComponent {
  basicForm!: FormGroup;
  accountDetail!: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private api: ApiService,
    private toast: ToastService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.accountDetail = navigation.extras.state;
    } else {
      console.log('No navigation state available');
      this.router.navigate(['/register']);
    }
  }

  ngOnInit(): void {
    if (this.accountDetail?.activeRole === 'Franchisee') {
      this.basicForm = this.fb.group({
        budgetTier: ['', Validators.required],
        occupation: ['', Validators.required],
        hadFranchise: [false, Validators.required],
        haveTime: [false, Validators.required],
      });
    } else if (this.accountDetail?.activeRole === 'Franchisor') {
      this.basicForm = this.fb.group({
        activeOutlet: ['', Validators.required],
        ownedStores: ['', Validators.required],
        targetOutlet: ['', Validators.required],
        companyAddress: ['', Validators.required],
      });
    }

    this.api.getProfile().subscribe((data) => {
      this.basicForm.patchValue(data);
    });
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (this.basicForm.valid) {
      this.api.updateProfile(this.basicForm.value).subscribe({
        next: (data) => {
          this.toast.show({textOrHtml: data.message, classname: 'bg-success text-white', delay: 1500});
          this.router.navigate(['/register/account-finished']);
        },
        error: (error) => {
          this.toast.show({textOrHtml: error.message, classname: 'bg-danger text-white', delay: 1500});
        },
      });
    }
  }

  skipStep() {
    this.router.navigate(['/register/account-finished']);
  }
}
