import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { ToastService } from '../services/toast.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private router: Router, private auth: AuthService, private toast: ToastService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    const excludedEndpoints = ['/register', '/login', '/maps/api'];
    const isExcluded = excludedEndpoints.some(url => 
      req.url.endsWith(url) || req.url.includes(`${url}?`) || req.url.includes(`${url}/`)
    );

    let clonedReq = req;
    if (token && !isExcluded) {
      clonedReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
    }

    return next.handle(clonedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if ((error.status === 401 && !clonedReq.url.includes('/auth/request')) && (error.status === 401 && !clonedReq.url.endsWith('/auth/login'))) {
          return this.handle401Error(clonedReq, next);
        } else if (error.status === 403) {
          this.handleAuthError(error);
        }
        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.auth.refreshToken().pipe(
        switchMap((response: any) => {
          // this.isRefreshing = false;
          const newToken = this.auth.extractToken(response);
          this.refreshTokenSubject.next(newToken);
          return next.handle(this.addTokenHeader(request, newToken));
        }),
        catchError((refreshError: HttpErrorResponse) => {
          this.isRefreshing = false;
          this.handleAuthError(refreshError);
          return throwError(() => refreshError);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => next.handle(this.addTokenHeader(request, token!)))
      );
    }
  }

  private addTokenHeader(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
  }

  private handleAuthError(error: HttpErrorResponse) {
    const currentUrl = this.router.url;
    const isAlreadyOnLoginPage = currentUrl.startsWith('/login');

    if (!isAlreadyOnLoginPage) {
      localStorage.setItem('returnUrl', currentUrl);
    }

    if (error.status === 401) {
      this.auth.logout();
      this.auth.getRoleSubject.next('');
      this.router.navigate(['/login'], { queryParams: { returnUrl: currentUrl } });
    } else if (error.status === 403) {
      this.router.navigate(['/not-authorized']);
    } else {
      this.toast.show({ textOrHtml: 'An error occurred. Please try again later.', classname: 'bg-danger text-white', delay: 3000 });
    }
  }
}
