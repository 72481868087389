import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { ResizeService } from './core/services/resize.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Franchise One';
  showHeaderPartials: boolean = true;
  hideHeaderPartialsRoutes: string[] = ['/login', '/register', '/forgot-password', '/profile/complete-profile'];
  showFooterPartials: boolean = true;
  hideFooterPartialsRoutes: string[] = ['/login', '/register', '/forgot-password', '/profile', '/profile/complete-profile', '/brands', '/dashboard', '/sales', '/orders', '/control'];
  hideFooterOnMobileRoutes: string[] = ['/brand', '/cart', '/checkout', '/order/'];
  isMobileView = this.resize.isMobileView(); 
  role: string = '';

  constructor(
    private router: Router,
    private titleService: Title,
    private resize: ResizeService,
    private auth: AuthService
  ) {
    console.log('13-02-2025-21-11')
    this.titleService.setTitle(this.title);
    this.auth.theRole$.subscribe({
      next: (role: string) => {
        this.role = role;
      },
      error: (error: any) => {
        console.error('Error in header component:', error);
      }
    });
  }
  
  ngOnInit(): void {
    this.resize.resize$.subscribe((isMobile: boolean) => {
      this.isMobileView = isMobile;
    });

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.showHeaderPartials = !this.hideHeaderPartialsRoutes.some(route => evt.urlAfterRedirects.startsWith(route));

        const hideFooterForRoute = this.hideFooterPartialsRoutes.some(route => evt.urlAfterRedirects.startsWith(route));
        const hideFooterForMobileRoute = this.isMobileView && this.hideFooterOnMobileRoutes.some(route => evt.urlAfterRedirects.startsWith(route));

        this.showFooterPartials = !(hideFooterForRoute || hideFooterForMobileRoute);
      }
    });
  }
}
