import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthGuard } from './auth/auth.guard';
import { CreateAccountComponent } from './auth/register/create-account/create-account.component';
import { VerifyAccountComponent } from './auth/register/verify-account/verify-account.component';
import { BasicInformationComponent } from './auth/register/basic-information/basic-information.component';
import { CongratulationsComponent } from './auth/register/congratulations/congratulations.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { VerifyOtpComponent } from './auth/reset-password/verify-otp/verify-otp.component';
import { SetPasswordComponent } from './auth/reset-password/set-password/set-password.component';
import { ResetSuccessComponent } from './auth/reset-password/reset-success/reset-success.component';
import { LoginManagerComponent } from './auth/login-manager/login-manager.component';
import { NotAuthorizedComponent } from './shared/components/not-authorized/not-authorized.component';
import { RoleGuard } from './auth/role.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'login-admin',
    component: LoginManagerComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'register/account-detail',
    component: CreateAccountComponent
  },
  {
    path: 'register/account-verification',
    component: VerifyAccountComponent,
  },
  {
    path: 'register/account-information',
    component: BasicInformationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'register/account-finished',
    component: CongratulationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'forgot-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'forgot-password/verify-otp',
    component: VerifyOtpComponent,
  },
  {
    path: 'forgot-password/set-password',
    component: SetPasswordComponent,
  },
  {
    path: 'forgot-password/reset-success',
    component: ResetSuccessComponent,
  },
  {
    path: 'home',
    loadChildren: () => import('../app/modules/homepage/homepage.module').then(m => m.HomepageModule)
  },
  {
    path: 'catalog',
    loadChildren: () => import('../app/modules/catalog/catalog.module').then(m => m.CatalogModule)
  },
  {
    path: 'brand/:identifier',
    loadChildren: () => import('../app/modules/brand/brand.module').then(m => m.BrandModule)
  },

  // Franchisee Routes
  {
    path: 'cart',
    loadChildren: () => import('../app/modules/cart/cart.module').then(m => m.CartModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRoles: ['Franchisee'] }
  },
  {
    path: 'checkout',
    loadChildren: () => import('../app/modules/checkout/checkout.module').then(m => m.CheckoutModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRoles: ['Franchisee'] }
  },
  {
    path: 'order',
    loadChildren: () => import('../app/modules/order/order.module').then(m => m.OrderModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRoles: ['Franchisee'] }
  },
  {
    path: 'wishlist',
    loadChildren: () => import('../app/modules/wishlist/wishlist.module').then(m => m.WishlistModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRoles: ['Franchisee'] }
  },

  // Franchisor Routes
  {
    path: 'dashboard',
    loadChildren: () => import('../app/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRoles: ['Franchisor'] }
  },
  {
    path: 'brands',
    loadChildren: () => import('../app/franchisor/brands/brands.module').then(m => m.BrandsModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRoles: ['Franchisor'] }
  },
  {
    path: 'orders',
    loadChildren: () => import('../app/franchisor/orders/orders.module').then(m => m.OrdersModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRoles: ['Franchisor'] }
  },

  // Sales Routes
  {
    path: 'sales',
    loadChildren: () => import('../app/sales/sales.module').then(m => m.SalesModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRoles: ['Sales'] }
  },

  // Admin Routes
  {
    path: 'admin/dashboard',
    loadChildren: () => import('../app/admin/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRoles: ['Management'] }
  },
  {
    path: 'admin/kyc',
    loadChildren: () => import('../app/admin/kyc/kyc.module').then(m => m.KycModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRoles: ['Management'] }
  },
  {
    path: 'admin/kyb',
    loadChildren: () => import('../app/admin/kyb/kyb.module').then(m => m.KybModule), 
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRoles: ['Management'] }
  },
  // Express Checkout Routes
  {
    path: 'express/:identifier',
    loadChildren: () => import('../app/modules/express/express.module').then(m => m.ExpressModule)
  },
  // Common Authorized Routes
  {
    path: 'profile',
    loadChildren: () => import('../app/modules/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
